import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Text } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import Copyright from "~/components/common/Copyright"
import { mq } from "~/components/common/theme"
import Source from "~/components/common/Source"

const Page = ({ myPage = 2, mySubpage = 0 }) => {
  const data = useStaticQuery(graphql`
      query {
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video4_16x9_CTM_190920_3bit" }
          ) {
              name
              publicURL
          }
          graphic1: file(name: { eq: "3.1a-ADIDAS-hat" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "3.1b-ADIDAS-network" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "3.1c-ADIDAS-wedidit" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic4: file(name: { eq: "3.1d-ADIDAS-highfive" }) {
              childImageSharp {
                  fluid(maxWidth: 500, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { video, graphic1, graphic2, graphic3, graphic4 } = data

  const graphics = [
    {
      text: "Ensure your girls’ safety is always being considered.",
      graphic: graphic1,
    },
    {
      text: "Create peer support networks.",
      graphic: graphic2,
    },
    {
      text:
        "Provide roles that assure girls of their importance to team success.",
      graphic: graphic3,
    },
    {
      text: "Introduce traditions that bring a sense of connection.",
      graphic: graphic4,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={
          <span>
            Many girls continue to play sports because of the social and
            emotional aspects of being&nbsp;on&nbsp;a&nbsp;team.<Source>22</Source>
          </span>
        }
        videoSrc={video.publicURL}
        videoLabel={video.name}
      />
      <Infobox uppercase>
        The importance of safety for kids is universal. A positive and
        supportive team culture encourages girls to take safe risks while
        knowing they have the support of the team. <br/>
        <br/>
        Given the previous lessons on why relationships matter for girls, you
        understand that physical and emotional safety is necessary for emotional
        connections to happen. A successful team culture will keep girls engaged
        and motivate them to come back year after year.
      </Infobox>

      <InfoboxDetailed
        headerMaxWidth={"desktop"}
        headerText={
          "Although there are many different elements to how culture is built and there’s no one-size-fits-all strategy for a successful team, there are four key themes that can help you cultivate a positive space for your team to grow and thrive."
        }
        py={6}
      >
        <Flex
          maxWidth={"desktop"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          my={6}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphics.map((item, index) => (
            <GraphicWithText
              image={item.graphic}
              width={["100%", "25%"]}
              px={[6, 5, 6]}
              key={index}
              mq={mq({
                width: ["50%", "100%"],
              })}
            >
              <Text mt={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                    text-transform: uppercase;
                  `}
                >
                  {item.text}
                </span>
              </Text>
            </GraphicWithText>
          ))}
        </Flex>
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            This section will explore how to ensure physical and emotional
            safety.
          </Text>
        </Box>
      </InfoboxDetailed>
      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 2, 0)
